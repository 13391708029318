import { graphql } from 'gatsby';
import React from 'react';

import BookADemo from 'components/shared/book-a-demo';
import Content from 'components/shared/content';
import MainLayout from 'layouts/main';
import { getReactContentWithLazyBlocks } from 'utils/get-react-content-with-lazy-blocks';

const Documentation = ({
  data: {
    wpPage: { content, seo, title },
  },
  pageContext,
}) => {
  const { reactedContent } = getReactContentWithLazyBlocks(content);
  return (
    <MainLayout seo={seo} pageContext={pageContext}>
      <Content content={reactedContent} title={title} withoutSubscribeBlock />
      <BookADemo />
    </MainLayout>
  );
};

export const query = graphql`
  query ($id: String!) {
    wpPage(id: { eq: $id }) {
      content
      title
      url: uri
      ...wpPageSeo
    }
  }
`;

export default Documentation;
